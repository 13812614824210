<template>
  <div class="infoPage">
    <div class="topImg">
      <img src="../../../assets/img/new/YHFQ.png" alt="">
    </div>

    <div class="conBox">
      <div class="infoBox">
        <div class="title">时尚会过去，但风格永存！</div>
        <div class="X"></div>
        <div class="textBox">
          近些年，随着复古潮流的兴起<br/>
          一股名为【中古】的潮流风向<br/>
          从日韩欧美，也卷到了古运河畔
        </div>
        <div class="textBox">
          喜欢Vintage<br/>
          大概是因为只此一件，绝无仅有<br/>
          当然撞品的几率几乎为零<br/>
          “日常”“个性化”“独特”的tag<br/>
          吸引着年轻的复古爱好者
        </div>
        <div class="textBox">
          每一件单品的背后<br/>
          都带着年代独有的文化印记<br/>
          都是设计师“跨时代”的情感链接
        </div>
        <div class="imgBox">
          <div class="left">
<!--            <img src="../../../assets/img/swiperPage/JDMST.png" alt="">-->
          </div>
          <div class="right">
<!--            <img src="../../../assets/img/swiperPage/JDMST.png" alt="">-->
          </div>
        </div>
      </div>
      <div class="Dynamic">
        <div class="title">
          <div class="text">
            其他推荐
          </div>
          <div class="lookAll">
            <!--            查看更多-->
            <span class="el-icon-arrow-left"></span>
            <span class="el-icon-arrow-right" style="margin-right: 0;"></span>
          </div>
        </div>
        <div class="DyList">
          <div class="item" v-for="(item,index) in 3">
<!--            <div class="img scale"><img src="../../../assets/img/swiperPage/PWYY5.png" alt=""></div>-->
            <div class="con">
              <div class="tit">
                清名桥历史文化街区上榜国家级…
              </div>
              <div class="time">
                2022-01-24
              </div>
              <div class="conText">
                文化和旅游部办公厅公示首批国家级旅游休闲街区名单，无锡市梁溪区清名桥历史文化街区等三地入选
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>

    <!--    <div class="paging-device">
          <el-pagination
              layout="prev, pager, next"
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :current-page="current_page"
              :page-size="per_page"
              :total="totalCount">
          </el-pagination>
        </div>-->
  </div>
</template>

<script>
import {gettravelStrategy, imgURL} from '@/api'
export default {
  name: "indexInfo",
  components:{
  },
  data(){
    return {
      imgURL,
      url:'',
      datalist:[],
    }
  },
  created() {

  },
  mounted() {
    // this.createlist()
  },
  methods:{
    // 每页条数
    handleSizeChange(val) {
      this.per_page=val
      this.createlist()
    },
    // 页数
    handleCurrentChange(val) {
      this.current_page=val
      this.createlist()
    },
    createlist(){

    },
    select(){

    },
    changep(){

    }
  }
}
</script>

<style scoped lang="less">
.infoPage{
  width: 100%;
  min-height: 800px;
  //padding: 0 0 0 55px;
  .topImg{
    width: 100%;
    img{
      width: 100%;
    }
  }
  .conBox{
    width: 1300px;
    margin: -100px auto 0;
    padding-bottom: 180px;
    position: relative;
    .infoBox{
      width: 100%;
      background: #FFFFFF;
      border-radius: 40px 40px 0px 0px;
      padding: 50px;
      .title{
        font-size: 34px;
        font-family: STSongti-SC-Black, STSongti-SC;
        font-weight: 900;
        color: #333333;
      }
      .X{
        width: 100%;
        height: 1px;
        background: #DDDDDD;
        margin: 50px 0;
      }
      .textBox{
        font-size: 14px;
        font-family: STSongti-SC-Bold, STSongti-SC;
        font-weight: bold;
        color: #333333;
        line-height: 30px;
        margin-bottom: 32px;
      }
      .imgBox{
        display: flex;
        margin-bottom: 200px;
        .left,.right{
          width: 50%;
          //width: 600px;
          //height: 800px;
          img{
            width: 100%;
            height: 100%;
          }
        }
        //.right{
        //  width: 50%;
        //  background: pink;
        //display: flex;
        //flex-wrap: wrap;
        //.item{
        //  flex: none;
        //  width: 50%;
        //  height: 400px;
        //}
        //}
      }
    }

    .Dynamic{
      //background: #F5F5F5;
      padding: 60px 0 0px 0;
      .title{
        width: 1200px;
        margin: 0px auto 30px;
        display: flex;
        justify-content: space-between;
        align-items: end;
        .text{
          font-size: 34px;
          font-family: STSongti-SC-Black, STSongti-SC;
          font-weight: 900;
          color: #333333;
          span{
            color: #337D8D;
          }
        }
        .lookAll{
          font-size: 20px;
          font-family: STSongti-SC-Regular, STSongti-SC;
          font-weight: 400;
          color: #000000;
          cursor: pointer;
          span{
            margin: 0 20px;
            font-size: 22px;
            font-weight: bold;
            cursor: pointer;
            &:hover{
              color: #337D8D;
            }
          }
        }
      }
      .DyList{
        width: 1200px;
        margin: auto;
        display: flex;
        justify-content: space-between;
        .item{
          flex: none;
          width: 380px;
          min-height: 450px;
          height: 100%;
          background: #FFFFFF;
          border-radius: 20px;
          overflow: hidden;
          &:hover{
            .img{
              transition:all 1000ms ease;
              //transform: scale(1);
              transform: scale(1.1);
            }
            .con>.tit{
              color: #337D8D;
            }
          }
          .img{
            width: 100%;
            height: 300px;
            transition:all 500ms ease;
            img{
              width: 100%;
              height: 100%;
            }
          }
          .con{
            padding: 24px;
            .tit{
              font-size: 20px;
              font-family: STSongti-SC-Black, STSongti-SC;
              font-weight: 900;
              color: #333333;
              display: -webkit-box;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 1; //行数
              overflow: hidden;
            }
            .time{
              font-size: 14px;
              font-family: STHeitiSC-Medium, STHeitiSC;
              font-weight: 500;
              color: #999999;
              margin: 16px 0;
            }
            .conText{
              font-size: 14px;
              font-family: STSongti-SC-Regular, STSongti-SC;
              font-weight: 400;
              color: #999999;
              display: -webkit-box;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 2; //行数
              overflow: hidden;
            }
          }

        }
      }
    }

  }


}
</style>

